<template>
  <div>
    <div v-if="$route.meta.isChildren">
      <div class="checkIn" v-if="!this.$hasConstruction('IEBDC:SY:DJBL:SFZJ')" v-loading="loading">
        <div class="flex">
          <div class="classification1">
            <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
              <el-tab-pane v-for="(item, index) in tabsData" :key="index" :label="item.name" :name="item.id">
                <span slot="label"><i :class="item.icon" class="mr10"></i>{{ item.name }}</span>
                <ul>
                  <li
                    v-for="(item, index) in djdlArry"
                    @click="levelClick(item, index)"
                    :key="index"
                    class="removeFloat checkList"
                    :class="currentIndex === index ? 'current' : ''"
                  >
                    <span class="fl"><i class="el-icon-document mr10"></i>{{ item }}</span>
                    <span class="fr"><i class="el-icon-arrow-right"></i></span>
                  </li>
                </ul>
              </el-tab-pane>
            </el-tabs>
          </div>
          <div class="classification2">
            <div class="title">{{ djdlName }}</div>
            <!-- <el-collapse v-if="levelProcess.length > 0" v-model="activeSecond">
              <el-collapse-item
                v-for="(itemThree, indexThree) in levelProcess"
                :key="indexThree"
                :title="itemThree.name"
                :name="indexThree.toString()"
              >
                <div
                  v-for="(itemFour, indexFour) in itemThree.thirdFlow"
                  :key="indexFour"
                  class="contentList removeFloat"
                >
                  <span class="fl">{{ itemFour.name }}</span>
                  <div class="fr">
                    <el-button type="primary" @click="onlineDeal(itemThree, itemFour)">在线办理 </el-button>
                  </div>
                </div>
              </el-collapse-item>
            </el-collapse> -->
            <el-collapse v-if="levelProcess.length > 0 && !dialogVisible && !stepVisible" v-model="activeSecond">
              <el-collapse-item
                v-for="(itemThree, indexThree) in levelProcess"
                :key="indexThree"
                :title="itemThree.name"
                :name="indexThree.toString()"
              >
                <div
                  v-for="(itemFour, indexFour) in itemThree.thirdFlow"
                  :key="indexFour"
                  class="contentList removeFloat"
                >
                  <span class="fl">{{ itemFour.name }}</span>
                  <div class="fr">
                    <el-button type="primary" @click="onlineDeal(itemThree, itemFour)">在线办理 </el-button>
                  </div>
                </div>
              </el-collapse-item>
            </el-collapse>
            <no-data v-if="levelProcess.length <= 0 && !loading"></no-data>
            <div class="tax-block" v-if="dialogVisible">
              <span class="dia-choice">请选择是否已经缴税</span>
              <div class="radio-block">
                <el-radio v-model="taxStatus" label="1" border>存量房买卖（线下缴税或已缴税）</el-radio>
                <el-radio v-model="taxStatus" label="2" border>存量房买卖（未缴税）</el-radio>
              </div>
              <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">上一步</el-button>
                <!-- <el-button type="primary" @click="firstStep()">下一步</el-button> -->
                <el-button type="primary" @click="nextStep()">下一步</el-button>
              </span>
            </div>
            <!-- <div class="tax-block" v-if="stepVisible">
              <span class="dia-choice">请选择是否在住建局合同备案</span>
              <div class="radio-block">
                <el-radio v-model="beianStatus" label="1" border>已备案</el-radio>
                <el-radio v-model="beianStatus" label="2" border>未备案（可以选择在线上签订合同）</el-radio>
              </div>
              <span slot="footer" class="dialog-footer">
                <el-button
                  @click="
                    stepVisible = false;
                    dialogVisible = true;
                  "
                  >上一步</el-button
                >
                <el-button type="primary" @click="nextStep()">下一步</el-button>
              </span>
            </div> -->
          </div>
        </div>
        <guide-to ref="guideRef"></guide-to>
      </div>
      <!--  后台配置权限该功能是否在建  -->
      <no-data v-else :data="dataNo"></no-data>
    </div>
    <transition name="fade" mode="out-in">
      <router-view />
    </transition>
  </div>
</template>

<script>
import { getFirstFlow, getThirdFlow } from 'iebdc/api/guidance.js';
import { mapMutations } from 'vuex';
import { localDataUser } from 'src/pages/iebdc/utils/local-data';
import guideTo from './guide-to/index';
import hasConstruction from '@iebdc/mixin/hasConstruction';
import { getCookie } from '../../../utils/cookies';

export default {
  name: 'registrationFor',
  components: { guideTo },
  mixins: [hasConstruction],
  data() {
    return {
      dataNo: {
        src: require('@/pages/iebdc/assets/images/nodata@2x.png'),
        imgWidth: '242px',
        imgHeight: '194px',
        title: '敬请期待',
        description: '正在建设中...',
      },
      dialogVisible: false,
      stepVisible: false,
      taxStatus: '2',
      beianStatus: '2',
      loading: true,
      activeName: 'HOUSE',
      activeSecond: ['0'],
      // 级联选择类型
      tabsData: [
        { id: 'HOUSE', name: '房屋', icon: 'el-icon-s-home' },
        { id: 'LAND', name: '土地', icon: 'el-icon-menu' },
        { id: 'WOODLAND', name: '林地', icon: 'el-icon-guide' },
      ],
      djdlArry: [], //接口返回的一级展示数据
      currentIndex: 0,
      djdlName: '首次登记',
      levelProcess: [], //接口返回的一级对应的二级数据
      lcList: [], //一级二级三级数据
      secondTypeData: [], //需要赋值的流程key
      baseData: {}, //办事指南数据
      documentList: [],
      selectedOrganizationsCode: getCookie('selectedOrganizationsCode'),
      isShowLoading: [],
      _itemThree: {},
      _itemFour: {},
    };
  },
  mounted() {
    this.getFirstFlow();
  },
  beforeDestroy() {
    this.$bus.$off('selectedOrganizationsCode');
  },
  methods: {
    // 保存一级流程数据方法
    ...mapMutations('wysq-data', ['setFirstFlow', 'getStepList']),
    ...mapMutations('taxStatus', ['setTaxStatus', 'setbeianStatus']),

    handleClick() {
      //房屋以及土地分类的切换
      this.currentIndex = 0;
      this.getFirstFlow();
    },
    handleClose(done) {
      this.$confirm('确认关闭？')
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
    getFirstFlow() {
      let flowType = localDataUser.get().userCategory;
      // 未实名认证，提示
      if (flowType === 'WSMRZ') {
        this.$message.error('用户未实名认证，请前往实名认证！');
        return;
      }
      //获取一二级数据
      this.lcList = [];
      this.djdlArry = [];
      getFirstFlow({
        bdclb: this.activeName,
        flowType: flowType,
        djjg: this.selectedOrganizationsCode,
      })
        .then((res) => {
          if (res.success) {
            let data = res.data;
            if (data) {
              // console.log(data, 'as');
              // this.lcList = data;
              data.forEach((item) => {
                if (item.visible) {
                  this.lcList.push(item);
                  this.djdlArry.push(item.name);
                }
                this.djdlName = this.djdlArry[0];
              });
              this.restrucFirstFlow(0);
            }
          }
        })
        .catch(() => {});
    },
    levelClick(name, index) {
      //房屋，土地对应的下拉数据的切换
      this.djdlName = name;
      this.currentIndex = index;
      this.restrucFirstFlow(index);
    },
    restrucFirstFlow(index) {
      //根据一级切换展示对应的二级数据
      this.loading = true;
      this.levelProcess = [];
      this.isShowLoading = [];
      let data = this.lcList;
      let typeData = data[index].flowDefConfigs;
      this.secondTypeData = data[index];
      this.pushLoading(typeData);
      typeData.forEach((item) => {
        if (item.visible) {
          this.getThird(item.id, item);
        }
      });
    },
    pushLoading(typeData) {
      typeData.forEach((item) => {
        if (item.visible) {
          this.isShowLoading[item.id] = true;
        }
      });
      //   console.log(this.isShowLoading);
    },
    isLoading() {
      // console.log(this.isShowLoading);
      this.loading = !Object.values(this.isShowLoading).every((element) => element === false);
      // console.log(this.loading);
    },
    getThird(id, item) {
      //获取三级流程判断二级流程下是否有对应的流程类型，没有的话不展示二级数据
      let flowType = localDataUser.get().userCategory;
      let param = {
        flowType: flowType,
        djjg: this.selectedOrganizationsCode,
        parentId: id,
      };
      getThirdFlow(param)
        .then((res) => {
          this.isShowLoading[id] = false;
          this.isLoading();
          if (res.success && res.data) {
            if (res.data.page && res.data.page.length > 0) {
              item.thirdFlow = res.data.page;
              var page1 = res.data.page.filter((item) => {
                return item.visible;
              });
              item.thirdFlow = page1;
              if (page1.length !== 0) {
                this.levelProcess.push(item);
              }

              // 2020.12.10解决二级菜单在一级菜单重复点击下会出现的乱序问题,我的解决办法是重新排序
              this.levelProcess.sort((a, b) => {
                return a.sort - b.sort;
              });
            } else {
              // this.$message.warning(res.message);
            }
          } else {
            this.$message.warning(res.message);
          }
          this.$nextTick(() => {
            this.activeSecond = ['0'];
          });
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        }).finally(()=>{
          this.loading = false;
        });
    },
    firstStep() {
      this.dialogVisible = false;
      this.stepVisible = true;
    },
    nextStep() {
      this.stepVisible = false;
      //点击在线办理
      let arrayData = [];
      arrayData.push(this.secondTypeData);
      arrayData.push(this._itemThree);
      arrayData.push(this._itemFour);
      //百度统计
      if (window.CONFIG.isOpenBDTJ === true)
        _hmt.push(['_trackEvent', '在线办理', this.selectedOrganizationsCode, this._itemThree.name]);
      // 需要赋值的流程key
      const flowKey = ['secondTypeData', 'thirdTypeData', 'fourTypeData'];
      // 保存流程数据
      const flowData = flowKey.reduce((acc, cur, index) => {
        acc[cur] = arrayData[index];
        return acc;
      }, {});
      console.log('[点击在线办理] 2 flowData', flowData);
      this.setFirstFlow(flowData);
      this.$router.push({
        name: 'apply',
      });
      console.log('taxStatus===' + this.taxStatus);
      console.log('beianStatus===' + this.beianStatus);
      this.setTaxStatus(this.taxStatus);
      this.setbeianStatus(this.beianStatus);
    },
    onlineDeal(itemThree, itemFour) {
      console.log('[点击在线办理 itemThree, itemFour]', itemThree);
      console.log('[点击在线办理 , itemFour]', itemFour);
      this._itemThree = itemThree;
      this._itemFour = itemFour;
      if (itemThree.flowCode === 'F200104') {
        //存量房转移
        this.dialogVisible = true;
      } else {
        this.nextStep();
      }
    },
    guideClick(data) {
      //点击办事指南
      this.$refs.guideRef.open(data);
    },
    logInfo() {
      console.info('[我要申请 START]---------------------------------------------');
      console.info('this.tabsData', this.tabsData);
      console.info('this.djdlArry', this.djdlArry);
      console.info('this.levelProcess', this.levelProcess);
      console.info('[我要申请   END]---------------------------------------------');
    },
  },
};
</script>

<style scoped lang="scss">
@import 'src/pages/iebdc/styles/public';
@import 'src/pages/iebdc/styles/common-variables';

.fl {
  float: left;
}

.fr {
  float: right;
}

.mr10 {
  margin-right: 10px;
}

.removeFloat:after {
  display: block;
  clear: both;
  content: '';
  visibility: hidden;
  height: 0;
}

.removeFloat {
  zoom: 1;
}

.checkIn {
  .classification1,
  .classification2 {
    box-sizing: border-box;
  }
  .classification1 /deep/ {
    margin-right: 30px;
    background: #fcfcfc;
    // width: 312px;
    color: #333333;
    .el-tabs--card > .el-tabs__header {
      box-sizing: border-box;
      margin-bottom: 0;
      height: 50px;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      overflow: hidden;
    }
    .el-tabs__content {
      border-left: 1px solid #eceaea;
      border-right: 1px solid #eceaea;
      border-bottom: 1px solid #eceaea;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }
    .el-tabs--card > .el-tabs__header .el-tabs__nav {
      border: none;
      width: 100%;
    }
    .el-tabs__item.is-active {
      color: #ffffff !important;
      background: #2c8bff;
    }
    .el-tabs__item:hover {
      color: inherit;
    }
    .el-tabs--card > .el-tabs__header .el-tabs__item {
      text-align: center;
      width: 30%;
      font-size: 18px;
      border-bottom: 1px solid #2c8bff;
      border-left: none;
      border-right: none;
      height: 50px;
      line-height: 50px;
    }
  }
  .checkList {
    width: 100%;
    height: 60px;
    line-height: 60px;
    font-size: 16px;
    color: #414141;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
    cursor: pointer;
  }
  .checkList.current {
    color: #2c8bff;
  }
  .classification2 /deep/ {
    flex: 2;
    position: relative;
    border-radius: 5px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    box-sizing: border-box;
    min-height: 531px;
    // width: 808px;
    border: 1px solid #eceaea;
    .title {
      font-size: 18px;
      color: #414141;
      height: 50px;
      line-height: 50px;
      border-bottom: 1px dashed #eceaea;
      font-weight: bolder;
    }
    ul {
      list-style-type: none;
    }
    .el-collapse {
      border-top: none;
      /*border-bottom: 1px dashed #ECEAEA;*/
    }
    .el-collapse-item__wrap {
      border-bottom: 1px dashed #eceaea;
    }
    .el-collapse-item__header {
      height: 70px;
      line-height: 70px;
      border-bottom: 1px dashed #eceaea;
      font-size: 16px;
    }
    .el-collapse-item__header.is-active {
      border-bottom-color: transparent;
    }
    .contentList {
      line-height: 32px;
      font-size: 14px;
      position: relative;
      padding-left: 12px;
    }
    .contentList:not(:last-child) {
      margin-bottom: 20px;
    }
    .contentList:before {
      content: '';
      position: absolute;
      width: 6px;
      height: 6px;
      background: #414141;
      left: 0;
      margin-top: -3px;
      top: 50%;
      border-radius: 100%;
    }
  }
}
.tax-block {
  position: relative;
  margin: 20px;
}
.dia-choice {
  position: absolute;
  display: inline-block;
  font-size: 18px;
  margin-bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  font-weight: bold;
  margin-top: 20px;
}
.radio-block {
  position: absolute;
  display: flex;
  margin-top: 80px;
  left: 50%;
  transform: translateX(-50%);
}
.dialog-footer {
  position: absolute;
  margin-top: 150px;
  left: 40%;
}
</style>
